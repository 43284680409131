import PropTypes from 'prop-types';
import { useMemo, useCallback, useState, useEffect } from 'react';
import { useAuth0, Auth0Provider } from '@auth0/auth0-react';
// config
import { AUTH0_API } from 'src/config-global';
//
import { AuthContext } from './auth-context';
import { useLocalStorage } from 'src/hooks/use-local-storage';

// ----------------------------------------------------------------------

// TODO: Create an interface where users can sign into LinkedIn, Indeed, etc. when submitting an application or employers can quickly use my interface to send an email request or a request that's readable within great pursuits to send their data over so they can see a report containing personality type and other data
// TODO: 1. Business can create account 2. Request a users data via email 3. user signs in to great putsuits and grants access to specific data and can manage which companies they shared their data with
// TODO: Create workflow integration where companies can have this request automatically sent when a user applied for the job. Research how companies are currently setting up their job postings (workday, linkedin, etc.)

function AuthProviderWrapper({ children }) {
  const { isAuthenticated, user, isLoading, loginWithRedirect, logout, getAccessTokenSilently } = useAuth0();

  // TODO: Remove
  const [popupClick] = useState(true);

  // User data process
  // 1. Intialize user data state with user object from local storage.
  // If not found, initialize as {}
  // (See: auth-guard.js)
  const [userData, setUserData] = useLocalStorage('user-profile', {});

  // TODO: Remove if not using
  const handleLogout = useCallback(
    async (options) => {
      logout?.(options);
    },
    [logout]
  );

  useEffect(
    () => {
      // User data process
      // 2. When isAuthenticated changes, if user data doesn't already exist, set user id and email from auth0
      // (See: auth-guard.js)
      if (!isLoading) {
        if (isAuthenticated) {
          const userId = userData?.id;
          if (!userId) {
            setUserData({ id: user?.sub, email: user?.email });
          }
        } else {
          setUserData({});
        }
      }
    },
    // Only user?.sub is needed as a dependency as changes to
    // the id will cover situations where the user object changes
    // ---
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isLoading, isAuthenticated, user?.sub, userData?.id]
  );

  // GET ACCESS TOKEN
  const getAccessToken = useCallback(async () => {
    let token;
    try {
      token = getAccessTokenSilently?.();
    } catch (err) {
      await loginWithRedirect?.();
      token = getAccessTokenSilently?.();
    } finally {
      return token;
    }
  }, [getAccessTokenSilently, loginWithRedirect]);

  // ----------------------------------------------------------------------

  const checkAuthenticated = isAuthenticated ? 'authenticated' : 'unauthenticated';

  const status = popupClick && isLoading ? 'loading' : checkAuthenticated;

  const handleLoginWithRedirect = useCallback(async () => {
    try {
      await loginWithRedirect?.({
        appState: {
          returnTo: window.location.href,
        },
      });
    } catch (error) {
      console.error(error);
    }
  }, [loginWithRedirect]);

  /**
   * 5. Save user data to localstorage
   */
  const onUpdateUser = useCallback((data) => {
    setUserData(data);
  }, []);

  const memoizedValue = useMemo(
    () => ({
      user: userData,
      method: 'auth0', // TODO: REMOVE METHOD
      loading: status === 'loading',
      authenticated: status === 'authenticated',
      unauthenticated: status === 'unauthenticated',
      //
      onUpdateUser,
      getAccessTokenSilently: getAccessToken,
      handleLoginWithRedirect: handleLoginWithRedirect,
      logout: handleLogout,
    }),
    [status, userData, handleLogout, onUpdateUser, getAccessToken, handleLoginWithRedirect]
  );

  // // TODO: Remove if not using
  // useEffect(() => {
  //   console.debug('Global user state:');
  //   console.debug(memoizedValue?.user);
  // }, [memoizedValue?.user]);

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}

AuthProviderWrapper.propTypes = {
  children: PropTypes.node,
};

// ----------------------------------------------------------------------

export const AuthProvider = ({ children }) => {
  const domain = AUTH0_API.domain ?? '';
  const clientId = AUTH0_API.clientId ?? '';
  const redirectUri = AUTH0_API.callbackUrl ?? '';
  const audience = AUTH0_API.audience ?? '';

  const onRedirectCallback = useCallback((appState) => {
    window.location.replace(appState?.returnTo || window.location.pathname);
  }, []);

  if (!(domain && clientId && redirectUri)) {
    return null;
  }

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        audience: audience,
        redirect_uri: redirectUri,
      }}
      onRedirectCallback={onRedirectCallback}
      // TODO: Investigate this cacheLocation attribute
      cacheLocation="localstorage"
    >
      <AuthProviderWrapper>{children}</AuthProviderWrapper>
    </Auth0Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node,
};
