import axios from 'axios';
// config
import { HOST_API } from 'src/config-global';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({ baseURL: HOST_API });

axiosInstance.interceptors.response.use(
  (response) => {
    // TODO: Add a check to see if in production or dev mode to log all axios responses/errors
    // console.log({ response });
    return response;
  },
  (error) => {
    // TODO: Add a check to see if in production or dev mode to log all axios responses/errors
    // console.log({ error });
    return Promise.reject((error.response && error.response.data) || 'Something went wrong');
  }
);

export default axiosInstance;

export const API_ENDPOINTS = {
  career: {
    featured: '/careers/featured',
    find: '/careers/search',
  },
  user: {
    profile: '/user/profile',
  },
};
