import sanitizeHtml from 'sanitize-html';

const defaultOptions = {
  allowedTags: ['[p]'],
  allowedAttributes: {
    p: [],
  },
  allowedIframeHostnames: [],
};

export function sanitize(dirty, options) {
  return {
    __html: sanitizeHtml(dirty, { ...defaultOptions, ...options }),
  };
}

/**
 * Allows dynamic update the theme-color
 * property in html head
 * @param {*} color
 */
export function setThemeColor(color) {
  // Check if the theme-color meta tag already exists
  let metaThemeColor = document.querySelector('meta[name="theme-color"]');

  // If the meta tag doesn't exist, create it
  if (!metaThemeColor) {
    metaThemeColor = document.createElement('meta');
    metaThemeColor.name = 'theme-color';
    document.head.appendChild(metaThemeColor);
  }

  // Update the content attribute to set the new color
  metaThemeColor.setAttribute('content', color);
}

/**
 * Determine if the user has dark mode enabled on their native device
 */
export function isDarkMode() {
  return window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
}
