import { lazy } from 'react';
import { Navigate, useRoutes, Outlet } from 'react-router-dom';
// layouts
import MainLayout from 'src/layouts/main';
//
import { dashboardRoutes } from './dashboard';

// ----------------------------------------------------------------------

const HomePage = lazy(() => import('src/pages/home'));
// const Page500 = lazy(() => import('src/pages/500'));
const Page403 = lazy(() => import('src/pages/403'));
// const Page404 = lazy(() => import('src/pages/404')); <--- TODO:
// const ComingSoonPage = lazy(() => import('src/pages/coming-soon'));
// const MaintenancePage = lazy(() => import('src/pages/maintenance'));

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    // SET INDEX PAGE WITH HOME PAGE
    {
      path: '/',
      element: (
        <MainLayout>
          <Outlet />
        </MainLayout>
      ),
      children: [{ element: <HomePage />, index: true }],
    },

    // Dashboard routes
    ...dashboardRoutes,

    // TODO: Set these up - maybe move to a main.js file?
    // { path: 'coming-soon', element: <ComingSoonPage /> },
    // { path: 'maintenance', element: <MaintenancePage /> },
    // { path: '500', element: <Page500 /> },
    // { path: '404', element: <Page404 /> },
    { path: '403', element: <Page403 /> },

    // No match 404
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
