import PropTypes from 'prop-types';
import { useEffect, useCallback, useState } from 'react';
import { isEmpty } from 'lodash';
//
import { useAuthContext } from '../hooks';
import { useSnackbar } from 'src/components/snackbar';
import InactivityLogout from '../inactivity-logout';
// service
import { getUserProfile } from 'src/service/profile';

// ----------------------------------------------------------------------

export default function AuthGuard({ children }) {
  const { authenticated, handleLoginWithRedirect, user, onUpdateUser, getAccessTokenSilently } = useAuthContext();
  const { enqueueSnackbar } = useSnackbar();
  const [checked, setChecked] = useState(false);
  const [userData, setUserData] = useState(user);

  const handleError = useCallback(
    (error) => {
      console.error(error);
      enqueueSnackbar('Something went wrong', { variant: 'error' });
    },
    [enqueueSnackbar]
  );

  // User data process
  // 4. Now that userData has been updated, update the original user object from useAuthContext
  // (See: auth-provider.js)
  useEffect(() => {
    onUpdateUser({ ...userData });
  }, [userData, onUpdateUser]);

  // User data process
  // 3. When authenticated, get user data from DB and set it to userData
  // (initialized with the localstorage user object from useAuthContext).
  // Default introductionComplete as false. This will be
  // updated to true if db value for this field is true. This will allow the
  // application to understand if supplementary user profile data was
  // retrieved. Using 'introductionComplete' as it indicates whether introductory
  // information was collected.
  // (See: auth-provider.js)
  useEffect(
    () => {
      async function getuserData() {
        if (!isEmpty(user?.id) && userData?.introductionComplete === undefined) {
          try {
            if (!authenticated) handleLoginWithRedirect();
            const accessToken = await getAccessTokenSilently();
            const { data: profile, error } = await getUserProfile(accessToken, user?.id);
            if (error) handleError(error);
            else setUserData({ introductionComplete: false, ...user, ...profile });
          } catch (error) {
            handleError(error);
          }
        }
      }
      getuserData();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [authenticated, user?.id, userData?.id, getAccessTokenSilently, handleError, handleLoginWithRedirect]
  );

  const check = useCallback(() => {
    if (!authenticated) {
      handleLoginWithRedirect();
    } else {
      setChecked(true);
    }
  }, [authenticated, handleLoginWithRedirect]);

  useEffect(() => {
    check();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!checked) {
    return null;
  }

  return (
    <>
      {children}
      <InactivityLogout />
    </>
  );
}

AuthGuard.propTypes = {
  children: PropTypes.node,
};
